<template>
    <div class="verification-page">
        <el-card  class="verification-card" :body-style="{ padding: '20px' }">
            <h2 class="verification-title">重置密码</h2>
            <el-form :model="formData" ref="form" :rules="rules">
                    <!-- 密码号输入框 -->
                    <el-form-item label="输入新密码" prop="pwd">
                        <el-input v-model="formData.pwd" clearable placeholder="输入新密码" show-password></el-input>
                    </el-form-item>

                    <!-- 确认密码输入框 -->
                    <el-form-item label="确认新密码" prop="confirmPwd">
                        <el-input v-model="formData.confirmPwd" clearable placeholder="确认新密码" show-password></el-input>
                    </el-form-item>

                    <!-- 手机号输入框 -->
                    <el-form-item label="手机号" prop="phone">
                        <el-input  v-model="formData.phone" clearable placeholder="请输入手机号"></el-input>
                    </el-form-item>

                    <!-- 验证码输入框 -->
                    <el-form-item prop="verificationCode">
                        <el-button type="primary" @click="sendVerificationCode" :disabled="countdown > 0">{{ countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}</el-button>
                        <el-input  style="width: 236px;margin-right: 10px;" v-model="formData.verificationCode" clearable placeholder="请输入验证码"></el-input>
                    </el-form-item>

                    <!-- 操作按钮 -->
                    <div class="verification-buttons">
                        <el-button type="primary" @click="completeSetup">完成设置</el-button>
                    </div>
            </el-form>
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialoaType:1,
                formData: {
                    phone: '',
                    verificationCode: '',
                    pwd: '',
                    confirmPwd: '',
                },
                countdown: 0,
                rules: {
                    phone: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' },
                    ],
                    verificationCode: [
                        { required: true, message: '请输入验证码', trigger: 'blur' },
                    ],
                    pwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { min: 6, max: 20, message: '密码长度应为6-20个字符', trigger: 'blur' },
                    ],
                    confirmPwd: [
                        { required: true, message: '请确认密码', trigger: 'blur' },
                        { validator: this.confirmPwd, trigger: 'blur' },
                    ],
                },
            };
        },
        methods: {
            sendVerificationCode() {
                var that = this;
                // 处理发送验证码操作
                if (this.countdown === 0) {
                    // 在这里可以添加发送验证码的逻辑，例如调用后端接口
                    // 成功发送后开始倒计时
                    this.countdown = 60;
                    const timer = setInterval(() => {
                        this.countdown -= 1;
                        if (this.countdown <= 0) {
                            clearInterval(timer);
                        }
                    }, 1000);

                    //发送验证码
                    that.newApi.sendSms({mobile:that.formData.phone,smsType:8}).then((res) => {
                        if(res.isSuccess == 1){
                            that.utils.sus(res.data);
                        }
                    })
                }
            },
            completeSetup(){
                //完成设置
                var that = this;
                if(this.formData.pwd == "" || this.formData.confirmPwd == ""){
                    this.utils.err("密码不能为空");
                    return false;
                }
                if(this.formData.pwd != this.formData.confirmPwd){
                    this.utils.err("密码不一致");
                    return false;
                }
                if(this.formData.phone == ""){
                    this.utils.err("手机号不能为空");
                    return false;
                }
                if(this.formData.verificationCode == ""){
                    this.utils.err("验证码不能为空");
                    return false;
                }
                var params = {};
                params.mobile = this.formData.phone;
                params.code = this.formData.verificationCode;
                params.newPwd = this.formData.pwd;
                params.confirmPwd = this.formData.confirmPwd;
                that.newApi.forgetBgbPayPwd(params).then((res) => {
                    if (res.isSuccess == 1){
                        that.formData.phone = "";
                        that.formData.verificationCode = "";
                        that.formData.pwd = "";
                        that.formData.confirmPwd = "";
                        that.utils.sus(res.data);
                        this.$emit("change", false);
                    }
                })
            }
        },
    };
</script>

<style scoped>
    .verification-page {
        justify-content: center;
        align-items: center;
    }

    .verification-card {
        width: 400px;
        max-width: 100%;
    }

    .verification-title {
        font-size: 1.5em;
        margin-bottom: 20px;
    }

    .verification-buttons {
        margin-top: 20px;
        text-align: right;
    }
    /* 设置验证码输入框和按钮在同一行 */
    .identity-verification-buttons {
        margin-top: 20px;
        text-align: right;
    }

    /* 调整验证码按钮的样式，使其在同一行显示 */
    .identity-verification-buttons .el-button {
        margin-left: 10px;
    }
</style>
