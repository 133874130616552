<template>
    <div class="security">
        <div>
            <div style="display: flex; margin-bottom: 3px; background-color: #FFFFFF ">
              <div style="height: 43px; padding: 20px 30px 0 30px; display: flex;font-size: 18px; font-weight: 600;">
                <div style="display: flex">
                  <div class="hand" @click="tag = 'account'" :class="tag === 'account' ? 'select-tag' : 'top-tag'">
                    保护账号安全
                  </div>
                  <div class="hand" @click="tag = 'money'" :class="tag === 'money' ? 'select-tag' : 'top-tag'">
                    保护资金安全
                  </div>
<!--                  <div style="margin-right: 40px; color: #CCCCCC; font-weight: 400"> | </div>-->
                </div>

<!--                <div>-->
<!--                  <span style="margin-right: 70px">安全等级：-->
<!--                      <span style="display: inline-block; width: 280px; height: 12px; border-radius: 30px; background-color: #69AA00; margin-right: 10px"></span>-->
<!--                      <span style="color: #69AA00; font-size: 16px">高</span>-->
<!--                  </span>-->
<!--                  <span style="color: #999999; font-size: 12px;color: #F69404;">建议您设置全部安全设置，以保障账户及资金安全</span>-->
<!--                </div>-->

              </div>
            </div>
            <div style="background-color: #FFFFFF">
              <div style="padding: 30px 40px" v-if="tag === 'account'">
                <div style="display: flex">
                  <div style="display: flex" v-if="realInfo.account">
                    <img src="@/pages/user/imgs/zu1392.png" class="type-image">
                    <div class="type-name" style="color: #69AA00;width: 60px;">保护中</div>
                  </div>
                  <div style="display: flex" v-else>
                    <img src="@/pages/user/imgs/zu3492.png" class="type-image">
                    <div class="type-name" style="color: #EE3F4D;width: 60px;">未设置</div>
                  </div>
                  <div class="division"></div>
                  <div style="width: 88%; display: flex; justify-content: space-between">
                    <div>
                      <span class="title">登录账号</span>
                      <span class="content">*您设置的登录账号为：<span style="color: #FF9800">{{realInfo.account}}</span></span>
                    </div>
                    <div class="hand op" v-if="!realInfo.account" @click="function () {type = 1; showPhoneWin = true}" v-else style="color: #EE3F4D">设置</div>
                  </div>
                </div>
                <el-divider></el-divider>
                <div style="display: flex">
                  <div style="display: flex" v-if="user.realNameStatus === 2">
                    <img src="@/pages/user/imgs/zu1392.png" class="type-image">
                    <div class="type-name" style="color: #69AA00;width: 60px;" >保护中</div>
                  </div>
                  <div style="display: flex" v-else>
                    <img src="@/pages/user/imgs/zu3492.png" class="type-image">
                    <div class="type-name" style="color: #EE3F4D;width: 60px;">未设置</div>
                  </div>

                  <div class="division"></div>
                  <div style="width: 88%; display: flex; justify-content: space-between">
                    <div>
                      <span class="title">实名认证</span>
                      <span class="content">*请进行实名认证</span>
                    </div>
                    <div class="hand op" v-if="user.realNameStatus === 2" @click="a('/auth')" style="color: #1479FF">查看</div>
                    <div class="hand op" @click="a('/auth')" v-else style="color: #EE3F4D">认证</div>
                  </div>
                </div>
                <el-divider></el-divider>
                <div style="display: flex">
                  <div style="display: flex" v-if="realInfo.loginPwd === 1">
                    <img src="@/pages/user/imgs/zu1392.png" class="type-image">
                    <div class="type-name" style="color: #69AA00;width: 60px;" >保护中</div>
                  </div>
                  <div style="display: flex" v-else>
                    <img src="@/pages/user/imgs/zu3492.png" class="type-image">
                    <div class="type-name" style="color: #EE3F4D;width: 60px;">未设置</div>
                  </div>

                  <div class="division"></div>
                  <div style="width: 88%; display: flex; justify-content: space-between">
                    <div>
                      <span class="title">登录密码</span>
                      <span class="content">*上次登录时间：{{realInfo.lastLoginTime}}</span>
                    </div>
                    <div class="hand op" @click="function () {type = 6; showPhoneWin = true}" v-if="realInfo.bgbPayPwd === 1" style="color: #1479FF">修改</div>
                    <div class="hand op" @click="function () {type = 6; showPhoneWin = true}" v-else style="color: #EE3F4D">设置密码</div>
                  </div>
                </div>
                <el-divider></el-divider>

                <div style="display: flex">
                  <div style="display: flex" v-if="realInfo.phone">
                    <img src="@/pages/user/imgs/zu1392.png" class="type-image">
                    <div class="type-name" style="color: #69AA00;width: 60px;" >保护中</div>
                  </div>
                  <div style="display: flex" v-else>
                    <img src="@/pages/user/imgs/zu3492.png" class="type-image">
                    <div class="type-name" style="color: #EE3F4D;width: 60px;">未设置</div>
                  </div>

                  <div class="division"></div>
                  <div style="width: 88%; display: flex; justify-content: space-between">
                    <div>
                      <span class="title">手机绑定</span>
                      <span class="content">*主要用于修改密码、找回账号等安全相关的事宜</span>
                    </div>
                    <div class="hand op" @click="function () {type = 2; showPhoneWin = true}" v-if="realInfo.phone" style="color: #1479FF">修改</div>
                    <div class="hand op" @click="function () {type = 2; showPhoneWin = true}" v-else style="color: #EE3F4D">绑定</div>
                  </div>
                </div>
                <el-divider></el-divider>
                <div style="display: flex">
                  <div style="display: flex" v-if="realInfo.email">
                    <img src="@/pages/user/imgs/zu1392.png" class="type-image">
                    <div class="type-name" style="color: #69AA00;width: 60px;" >保护中</div>
                  </div>
                  <div style="display: flex" v-else>
                    <img src="@/pages/user/imgs/zu3492.png" class="type-image">
                    <div class="type-name" style="color: #EE3F4D;width: 60px;">未设置</div>
                  </div>

                  <div class="division"></div>
                  <div style="width: 88%; display: flex; justify-content: space-between">
                    <div>
                      <span class="title">邮箱绑定</span>
                      <span class="content">*辅助邮箱进行修改密码、找回账号等安全相关的事宜</span>
                    </div>
                    <div class="hand op" @click="function () {type = 5; showPhoneWin = true}" v-if="realInfo.email" style="color: #1479FF">修改</div>
                    <div class="hand op" @click="function () {type = 5; showPhoneWin = true}" v-else style="color: #EE3F4D">绑定</div>
                  </div>
                </div>
                <el-divider></el-divider>
                <div style="display: flex">
                  <div style="display: flex" v-if="realInfo.wxId">
                    <img src="@/pages/user/imgs/zu1392.png" class="type-image">
                    <div class="type-name" style="color: #69AA00;width: 60px;" >保护中</div>
                  </div>
                  <div style="display: flex" v-else>
                    <img src="@/pages/user/imgs/zu3492.png" class="type-image">
                    <div class="type-name" style="color: #EE3F4D;width: 60px;">未设置</div>
                  </div>

                  <div class="division"></div>
                  <div style="width: 88%; display: flex; justify-content: space-between">
                    <div>
                      <span class="title">微信绑定</span>
                      <span class="content">*绑定微信后可以进一步增加账号安全性，以及在首页快捷登录账号</span>
                    </div>
                    <div class="hand op" @click="bindWx" v-if="realInfo.wxId" style="color: #1479FF">修改</div>
                    <div class="hand op" @click="bindWx" v-else style="color: #EE3F4D">绑定</div>
                  </div>
                </div>
                <el-divider></el-divider>
                <div style="display: flex">
                  <div style="display: flex" v-if="realInfo.qqId">
                    <img src="@/pages/user/imgs/zu1392.png" class="type-image">
                    <div class="type-name" style="color: #69AA00;width: 60px;" >保护中</div>
                  </div>
                  <div style="display: flex" v-else>
                    <img src="@/pages/user/imgs/zu3492.png" class="type-image">
                    <div class="type-name" style="color: #EE3F4D;width: 60px;">未设置</div>
                  </div>

                  <div class="division"></div>
                  <div style="width: 88%; display: flex; justify-content: space-between">
                    <div>
                      <span class="title">QQ绑定</span>
                      <span class="content">*绑定QQ后可以进一步增加账号安全性，以及在首页快捷登录账号</span>
                    </div>
                    <div class="hand op" @click="bindQQ" v-if="realInfo.qqId" style="color: #1479FF">修改</div>
                    <div class="hand op" @click="bindQQ" v-else style="color: #EE3F4D">绑定</div>
                  </div>
                </div>
              </div>

              <div style="padding: 30px 40px" v-if="tag === 'money'">
<!--                <div style="display: flex">-->
<!--                  <div style="display: flex" v-if="user.realNameStatus === 2">-->
<!--                    <img src="@/pages/user/imgs/zu1392.png" class="type-image">-->
<!--                    <div class="type-name" style="color: #69AA00;width: 60px;" >保护中</div>-->
<!--                  </div>-->
<!--                  <div style="display: flex" v-else>-->
<!--                    <img src="@/pages/user/imgs/zu3492.png" class="type-image">-->
<!--                    <div class="type-name" style="color: #EE3F4D;width: 60px;">未设置</div>-->
<!--                  </div>-->

<!--                  <div class="division"></div>-->
<!--                  <div style="width: 88%; display: flex; justify-content: space-between">-->
<!--                    <div>-->
<!--                      <span class="title">账户收益</span>-->
<!--                      <span class="content">*保证您在百工宝所有收益安全</span>-->
<!--                    </div>-->
<!--                    <div class="hand op"  @click="clickLink(1)" style="color: #1479FF">查看</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <el-divider></el-divider>-->
                <div style="display: flex">
                  <div style="display: flex" v-if="realInfo.bgbPayPwd === 1">
                    <img src="@/pages/user/imgs/zu1392.png" class="type-image">
                    <div class="type-name" style="color: #69AA00;width: 60px;" >保护中</div>
                  </div>
                  <div style="display: flex" v-else>
                    <img src="@/pages/user/imgs/zu3492.png" class="type-image">
                    <div class="type-name" style="color: #EE3F4D;width: 60px;">未设置</div>
                  </div>

                  <div class="division"></div>
                  <div style="width: 88%; display: flex; justify-content: space-between">
                    <div>
                      <span class="title">安全密码</span>
                      <span class="content">*提现、转账时输入，保护账号资金安全</span>
                    </div>
                    <div v-if="realInfo.bgbPayPwd === 1" style="color: #1479FF"><span class="hand op" @click="retrieve(2)">重置</span><span style="color: #CCCCCC; margin: 0 10px">|</span ><span class="hand op" @click="retrieve(1)">修改</span></div>
                    <div class="hand op" @click="setup" v-else style="color: #EE3F4D">设置密码</div>
                  </div>
                </div>
<!--                <el-divider></el-divider>-->
<!--                <div style="display: flex">-->
<!--                  <div style="display: flex" v-if="user.realNameStatus === 2">-->
<!--                    <img src="@/pages/user/imgs/zu1392.png" class="type-image">-->
<!--                    <div class="type-name" style="color: #69AA00;width: 60px;" >保护中</div>-->
<!--                  </div>-->
<!--                  <div style="display: flex" v-else>-->
<!--                    <img src="@/pages/user/imgs/zu3492.png" class="type-image">-->
<!--                    <div class="type-name" style="color: #EE3F4D;width: 60px;">未设置</div>-->
<!--                  </div>-->

<!--                  <div class="division"></div>-->
<!--                  <div style="width: 88%; display: flex; justify-content: space-between">-->
<!--                    <div>-->
<!--                      <span class="title">银行卡信息</span>-->
<!--                      <span class="content">*百工宝将按照《xxxxxx》的相关规定来保护您的银行卡信息安全</span>-->
<!--                    </div>-->
<!--                    <div class="hand op" v-if="user.realNameStatus === 2" @click="clickLink(6)" style="color: #1479FF">查看</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <el-divider></el-divider>-->
<!--                <div style="display: flex">-->
<!--                  <div style="display: flex" v-if="user.realNameStatus === 2">-->
<!--                    <img src="@/pages/user/imgs/zu1392.png" class="type-image">-->
<!--                    <div class="type-name" style="color: #69AA00;width: 60px;" >保护中</div>-->
<!--                  </div>-->
<!--                  <div style="display: flex" v-else>-->
<!--                    <img src="@/pages/user/imgs/zu3492.png" class="type-image">-->
<!--                    <div class="type-name" style="color: #EE3F4D;width: 60px;">未设置</div>-->
<!--                  </div>-->

<!--                  <div class="division"></div>-->
<!--                  <div style="width: 88%; display: flex; justify-content: space-between">-->
<!--                    <div>-->
<!--                      <span class="title">用户保证金</span>-->
<!--                      <span class="content">*对购物时交付的保证金进行全程监控管理，保证您的资金安全</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
        </div>
        <el-dialog :close-on-click-modal="false" :visible.sync="setupnow" width="500px">
          <SetPwd @change="setupnow = false"></SetPwd>
        </el-dialog>

        <el-dialog :visible.sync="retrievelod" width="500px" :close-on-click-modal="false">
          <RetrievePwd @change="retrievelod = false"></RetrievePwd>
        </el-dialog>
        <el-dialog class="select-demo" :visible.sync="changepwd" width="500px" :close-on-click-modal="false">
          <change-pwd @change="changepwd = false"></change-pwd>
        </el-dialog>
        <el-dialog class="select-demo" :visible.sync="forgetpwd" width="402px" :close-on-click-modal="false">
          <forgetPwd @change="forgetpwd = false"></forgetPwd>
        </el-dialog>
       <div v-show="securityStr == 'viewBankCards'">
           <viewBankCards></viewBankCards>
       </div>
      <!--绑定手机号弹窗/ 设置账户信息-->
      <LoginModule v-if="showPhoneWin === true" :type="type" @onClose="upDateClose"></LoginModule>
    </div>
</template>

<script>
    import SetPwd from "@/components/common/Pay/setPwd.vue";
    import RetrievePwd from "@/components/common/Pay/retrievePwd";
    import forgetPwd from "@/components/common/Pay/forgetPwd";
    import viewBankCards from "../../../components/common/Pay/viewBankCards.vue";
    import changePwd from "@/components/common/Pay/changePwd";
    import LoginModule from "@/components/common/LoginModule.vue";
    export default {
        name: "Security",
        components: {LoginModule, RetrievePwd, SetPwd,forgetPwd,viewBankCards,changePwd},
        data() {
            return {
                tag: 'account',
                setupnow: false,
                retrievelod: false,
                forgetpwd:false,
                securityStr:'all',
                changepwd : false,
                realInfo: {},
                type: '',
                showPhoneWin: false,
            }
        },

        computed: {
            user() {
                return this.$store.state.user
            },
        },

      created() {
        this.getRealInfo()
      },

      mounted() {

      },

        methods: {
            clickLink(index) {
                this.$emit('change', index);
            },
	        /**
	         * 绑定微信
	         */
	        bindWx:function () {
		        var _this = this;
		        this.newApi.wxOauth({"type":"bind"}).then(resp =>{
			        //window.open(resp.data.result, "_blank")
			        var width = width || 900;
			        var height = height || 540;
			        var left = (window.screen.width - width) / 2;
			        var top = (window.screen.height - height) / 2;
			        var win =window.open(resp.data, "_blank",
				        "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, left=" +
				        left + ", top=" + top + ", width=" + width + ", height=" + height);
			        //监听登录窗口是否关闭,登录成功后 后端返回关闭窗口的代码
			        var listener=setInterval(function(){
				        if(win.closed){
					        clearInterval(listener);//关闭监听
					        _this.$router.go(0) //刷新
				        }
			        },500)
		        }).catch(fail =>{
			        console.error(fail)
		        })
	        },
	        /**
	         * 绑定qq
	         */
	        bindQQ:function () {
		        var _this = this;
		        this.newApi.qqOauth({"type":"bind"}).then(resp =>{
			        //window.open(resp.data.result, "_blank")
			        var width = width || 900;
			        var height = height || 540;
			        var left = (window.screen.width - width) / 2;
			        var top = (window.screen.height - height) / 2;
			        var win =window.open(resp.data, "_blank",
				        "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, left=" +
				        left + ", top=" + top + ", width=" + width + ", height=" + height);
			        //监听登录窗口是否关闭,登录成功后 后端返回关闭窗口的代码
			        var listener=setInterval(function(){
				        if(win.closed){
					        clearInterval(listener);//关闭监听
					        _this.$router.go(0) //刷新
				        }
			        },500)
		        }).catch(fail =>{
			        console.error(fail)
		        })
	        },
            upDateClose() {
              this.showPhoneWin = false;
              this.getRealInfo()
            },

            // 获取用户信息
            getRealInfo() {
              this.newApi.getUserWalletInfo().then(res => {
                this.realInfo = res.data
              })
            },

            //银行卡详情
            securityClick(str){
                this.securityStr = str;
            },

            // 设置安全密码事件
            setup() {
                if(this.user.realNameStatus != 2 ){
                    return this.utils.msg("请先完成实名认证");
                }
                var that = this;
                that.setupnow = true;
            },

            // 修改或重置安全密码
            retrieve(type) {
                var that = this;
                if(type == 1){
                  that.changepwd = true;
                }else{
                  that.forgetpwd = true;
                }

            },
        }
    }
</script>

<style scoped>
.security {

}

.security .type-image {
  width: 28px;
  height: 30px;
  margin-right: 10px;
}

.security .top-tag {
  width: 120px;
  height: 40px;
  text-align: center;
  margin-right: 40px;
}

.security .select-tag {
  width: 120px;
  height: 40px;
  color: #2970FF;
  text-align: center;
  border-bottom: 3px #2970FF solid;
  margin-right: 40px;
}

.security .type-name {
  font-size: 18px;
  font-weight: 600;
}

.security .division {
  width: 2px;
  background-color: #CCCCCC;
  height: 20px;
  margin: 2px 30px 0 30px;
}

.security .title {
  font-size: 18px;
  font-weight: 600;
  margin-right: 20px;
}

.security .content {
  color: #999999;
}

    /deep/ .select-demo .el-dialog__body {
        padding: 0;
    }

    /deep/ .select-demo .el-dialog__header {
        padding: 0 20px 10px;
    }
</style>
