<template>
    <div class="verification-page">
        <el-card  class="verification-card" :body-style="{ padding: '20px' }">
            <h2 class="verification-title">修改密码</h2>

            <!-- 手机号输入框 -->
            <el-form :model="formData" ref="form" :rules="rules">

                <!-- 手机号输入框 -->
                <el-form-item label="输入原密码" prop="pwd">
                    <el-input v-model="formData.pwd" clearable placeholder="请输入密码" show-password></el-input>
                </el-form-item>

                <!-- 验证码输入框 -->
                <el-form-item label="输入新密码" prop="confirmPwd">
                    <el-input v-model="formData.confirmPwd" clearable placeholder="请输入新密码" show-password></el-input>
                </el-form-item>

                <!-- 验证码输入框 -->
                <el-form-item label="确认新密码" prop="confirmPwd">
                    <el-input v-model="formData.newPwd" clearable placeholder="请确认新密码" show-password></el-input>
                </el-form-item>

<!--                <div style="width: 305px; height: 25px; font-size: 12px; color: #26C6FD; margin-top: 5px;">-->
<!--                    <div style="line-height: 25px; float: right;">-->
<!--                        <el-link type="danger" @click="forgotPassword">忘记密码？</el-link>-->
<!--                    </div>-->
<!--                </div>-->

                <el-dialog :visible.sync="setForget" width="402px" :close-on-click-modal="false">
                    <forgetPwd @change="setForget = false"></forgetPwd>
                </el-dialog>

                <!-- 操作按钮 -->
                <div class="verification-buttons">
                    <el-button type="primary" @click="completeSetup">完成设置</el-button>
                </div>

            </el-form>
        </el-card>
    </div>
</template>

<script>
    import forgetPwd from "./forgetPwd";
    export default {
        components: {
            forgetPwd
        },
        data() {
            return {
                dialoaType:1,
                formData: {
                    pwd: '',
                    newPwd:'',
                    confirmPwd: '',
                },
                countdown: 0,
                rules: {
                    pwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { min: 6, max: 20, message: '密码长度应为6位数字', trigger: 'blur' },
                    ],
                    newPwd: [
                        { required: true, message: '请输入新密码', trigger: 'blur' },
                        { min: 6, max: 20, message: '密码长度应为6位数字', trigger: 'blur' },
                    ],
                    confirmPwd: [
                        { required: true, message: '请确认密码', trigger: 'blur' },
                        { validator: this.confirmPwd, trigger: 'blur' },
                    ],
                },
                setForget:false
            };
        },
        methods: {
            //修改密码提交
            completeSetup(){
                //完成设置
                var that = this;
                if(this.formData.pwd == "" ){
                    this.utils.err("原密码不能为空");
                    return false;
                }
                if(this.formData.newPwd == "" || this.formData.confirmPwd == ""){
                    this.utils.err("新密码不能为空");
                    return false;
                }
                if(this.formData.newPwd != this.formData.confirmPwd){
                    this.utils.err("新密码不一致");
                    return false;
                }
                if(this.formData.pwd == this.formData.newPwd){
                    this.utils.err("新老密码不能一样");
                    return false;
                }
                var params = {};
                params.oldPwd = that.formData.pwd;
                params.newPwd = that.formData.confirmPwd;
                that.newApi.saveBgbPayPwd(params).then((res) => {
                    if (res.isSuccess == 1){
                        that.utils.sus(res.data);
                        this.$emit("change", false);
                    }
                })
            },
            //忘记密码弹窗
            forgotPassword(){
                this.$emit("change", false);
                this.$nextTick(() => {
                    // Setting setForget to true after the DOM has been updated
                    this.setForget = true;
                });

            }


        },
    };
</script>

<style scoped>
    .verification-page {
        justify-content: center;
        align-items: center;
    }

    .verification-card {
        width: 400px;
        max-width: 100%;
    }

    .verification-title {
        font-size: 1.5em;
        margin-bottom: 20px;
    }

    .verification-buttons {
        margin-top: 20px;
        text-align: right;
    }
    /* 设置验证码输入框和按钮在同一行 */
    .identity-verification-buttons {
        margin-top: 20px;
        text-align: right;
    }

    /* 调整验证码按钮的样式，使其在同一行显示 */
    .identity-verification-buttons .el-button {
        margin-left: 10px;
    }
</style>
