<template>
    <div>
        <el-card v-if="banks.length > 0" class="bank-card-info">
            <div slot="header" class="clearfix">
                <span>银行卡信息</span>
                <el-radio-group v-model="selectedBank" class="radio-group">
                    <el-radio :label="index" v-for="(item,index) in banks" :key="index" @change="handleBankChange(index)">{{ item.bankName }}</el-radio>
                </el-radio-group>
            </div>
            <div>
                <el-form :model="selectedBankInfo" label-width="80px">
                    <el-form-item label="银行名称">
                      <el-input v-model="selectedBankInfo.bankName" disabled style="width: 300px;"></el-input>
                    </el-form-item>
                    <el-form-item label="户名">
                      <el-input v-model="selectedBankInfo.cardName" disabled style="width: 300px;"></el-input>
                    </el-form-item>
                    <el-form-item label="卡类别">
                      <el-input v-model="selectedBankInfo.cardType" disabled style="width: 300px;"></el-input>
                    </el-form-item>
                    <el-form-item label="银行卡号">
                        <el-input v-model="selectedBankInfo.cardId" disabled style="width: 300px;"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号">
                      <el-input v-model="selectedBankInfo.telNo" disabled style="width: 300px;"></el-input>
                    </el-form-item>
                    <el-form-item label="绑定时间">
                        <el-input v-model="selectedBankInfo.createTime" disabled style="width: 300px;"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <no-data v-if="banks.length == 0"></no-data>
    </div>
</template>

<script>
    import NoData from "@/components/common/NoData";
    export default {
        components:{
            NoData
        },
        data() {
            return {
                banks: [],
                selectedBank: 0, // Default selected bank
                selectedBankInfo: {} // Selected bank information
            };
        },
        watch: {
        },
        mounted() {
            this.getAdaPayBanCardList();
        },
        methods: {
            handleBankChange(index) {
                console.log('Bank changed:', this.index);
                that.selectedBankInfo = that.banks[index];
            },
            getAdaPayBanCardList(){
                var that = this;
                that.newApi.getAdaPayBanCardList({}).then((res => {
                    if(res.isSuccess == 1){
                        that.banks = res.data;
                        that.selectedBankInfo = res.data[0];
                    }
                }))
            }
        }
    };
</script>

<style scoped>
    .radio-group {
        margin-left: 20px;
    }
</style>
